import thumbs from "./121028-one-line-drawing-like-icon.json";
import analytics from "./33810-payments.json";
import MorphingGreen from "./71506-shape-morphing-green.json";
import ProblemSolvingTeam from "./73386-problem-solving-team.json";
import Technology from "./82661-technology.json";
import payment from "./86866-payment.json";
import SocialNetwork from "./91955-social-media-network.json";
import OpenUp from "./97262-open-up-dffs.json";

// import GuyPickUpTrash from "/img/GupPickupTrash.png";

// import Paper from "/img/Paper.png";
// import Business from "/img/Business.png";
// import Pakam from "/img/Pakam.png";
// import Image1 from "/img/Image1.png";
// import Image2 from "/img/Image2.png";
// import Image4 from "/img/image53.png";
// import Image5 from "/img/image53-1.png";
// import Image6 from "/img/image56.png";
// import Image7 from "/img/image57.png";
// import Image8 from "/img/image28.png";
// import Image9 from "/img/image31.png";
// import Image10 from "/img/image28-2.png";
// import Image11 from "/img/image28-1.png";
// import Image12 from "/img/image28-3.png";
// import Image13 from "/img/image28-4.png";
// import Image14 from "/img/image31-2.png";
// import Image15 from "/img/image31-3.png";
// import Image16 from "/img/image31-4.png";
// import PR from "/img/PressRelease.png";
// import Interview1 from "/img/Interview1.png";
// import Interview2 from "/img/Interview2.png";
// import Interview3 from "/img/Interview3.png";
// import Pub1 from "/img/Pub1.png";
// import Pub2 from "/img/Pub2.png";
// import Pub3 from "/img/Pub3.png";
// import Group from "/img/GRealease.png";
// import EP from "/img/EarnPay.png";
// import Dashboard from "/img/DashboardScreen.png";
// import PayDash from "/img/PayDashboard.png";
// import PayContent from "/img/PayContent.png";
// import PayPhone from "/img/PayPhone.png";
// import SmartPhone from "/img/SmartPhone.png";
// import EarnPhone from "/img/EarnPhone.png";
// import Route from "/img/route.png";
// import Globe from "/img/globe.png";
// import GlobeEU from "/img/globeEu.png";
// import BusWoman from "/img/BusinessWoman.png";
// import SmartRF from "/img/SmartRF.png";

export interface TechnologyModel {
  imgUrl?: any;
  title?: string;
  text?: string;
  link?: string;
  description?: string;
  isTitle?: boolean;
  linkUrl?: string;
  color?:string
}

export interface PerksBenefitsModel {
  imgUrl?: any;
  imgAlt: string;
  text?: string;
}

export const PerksBenefit: Array<PerksBenefitsModel> = [
  {
    imgUrl: "/svg/Challenging.svg",
    imgAlt: "Pakm",
    text: "Challenging Projects",
  },
  {
    imgUrl: "/svg/DiverseTeam.svg",
    imgAlt: "Pakam",
    text: "Diverse Team",
  },
  {
    imgUrl: "/svg/Enjoyable.svg",
    imgAlt: "Pakam",
    text: "Enjoyable Workspace",
  },
  {
    imgUrl: "/svg/Global.svg",
    imgAlt: "Pakam",
    text: "Global Benefits",
  },
  { imgUrl: "/svg/Hybrid.svg", imgAlt: "Pakam", text: "Hybrid Working" },
  {
    imgUrl: "/svg/Career.svg",
    imgAlt: "Pakam",
    text: "Career Development",
  },
];

export const serve: Array<TechnologyModel> = [
  {
    imgUrl: "/svg/cit.svg",
    title: "Citizens",
    text: "Pakam engages citizens in waste management with on-demand waste solutions and rewards for recycling.",
    link: "Read more",
    linkUrl: "/serve/citizens",
    color:'#DCFCE7'
  },
  {
    imgUrl: "/svg/biz.svg",
    title: "Business operators",
    text: "Pakam optimizes waste management businesses by streamlining their collections and operations for smoother transactions.",
    link: "Read more",
    linkUrl: "/serve/business-operators",
    color:'#FDF1DC'
  },
  {
    imgUrl: "/svg/agen.svg",
    title: "Agencies",
    text: "Pakam helps government agencies collect data, track progress, and enhance resilience using our smart enforcement and reporting platform.",
    link: "Read more",
    linkUrl: "/serve/agencies",
    color:'#FFEEF7'
  },
];
// export const subscription: Array<TechnologyModel> = [
//   {
//     imgUrl: analytics,
//     title: "Earn-as-You Waste",
//     text: ``,
//     link: "Read more",
//   },
//   {
//     imgUrl: payment,
//     title: "Pay-as-You Waste",
//     text: "",
//     link: "Read more",
//   },
//   {
//     imgUrl: thumbs,
//     title: "Smart Enforcement",
//     text: " ",
//     link: "Read more",
//   },
// ];
export const whatwedo: Array<TechnologyModel> = [
  {
    imgUrl: "/img/Reduced operational cost.png",
    title: "Ecosystem technology platform for waste ",
  },
  {
    imgUrl: "/img/coins.png",
    title: "Waste Management Consultancy ",
  },
  {
    imgUrl: "/img/entypo_news.png",
    title: "Data Provision",
  },
  {
    imgUrl: "/img/Better Revenue Assurance.png",
    title: "Building and deploying waste management infrastructure",
  },
];

export interface EarnWasteModel {
  imgSrc: any;
  imgAlt: string;
  text: string;
  reverse: boolean;
  top?: boolean;
}

export interface EarnWasteContentModel {
  imgSrc: any;
  imgAlt: string;
  header1: string;
  header2: string;
  reverse: boolean;
  sub_text: Array<string>;
  soonchecker?: boolean;
}

interface KPIData {
  heading: string;
  text: string;
}

interface FeaturesModel {
  imgSrc: any;
  imgAlt: string;
  text: string;
}

export const earnarticles: Array<EarnWasteModel> = [
  {
    text: 'Earn-as-You Waste is a recycling service that connects waste generators to waste collectors/aggregators in real-time, ensuring individuals capture value for their waste. The Earn-as-You Waste mobile application includes a household app that enables household members (as an individual or commercial user like schools, businesses etc.) to schedule a "pick-up" or "drop-off" of their recyclable waste. With the Earn-as-You Waste App, users can easily sort their waste from source, schedule collection, and receive monetary value of this waste in their wallet. Experience a seamless flow while using your Earn-as-You Waste application.',
    imgAlt: "",
    imgSrc: "/img/EarnPay.png",
    reverse: false,
    top: true,
  },
];

export const earncontentarticles: Array<EarnWasteContentModel> = [
  {
    imgSrc: "/img/EarnPay.png",
    imgAlt: "pickup",
    header1: "Pickup Schedule Process",
    header2: "How It Works",
    sub_text: [
      "Download and install the Pakam Household Application from google play store or IOS app store.",
      "Select your type of user (individual or commercial) and create an account.",
      "Sign In.",
      'Click schedule request and select the "schedule pickup" option for your sorted recyclable waste from within the Pakam app.',
      "A nearby collector will accept your schedule and gets routed to your location to complete the pickup.",
      "Your E-wallet is credited as soon as the request is completed. EARN-AS-YOU WASTE!!! Recycling made easy.",
    ],
    reverse: false,
  },

  {
    imgSrc: "/img/EarnPhone.png",
    imgAlt: "drop off",
    header1: "Drop off Schedule Process",
    header2: "How It Works",
    sub_text: [
      "Download and install the Pakam Household Application from google play store or IOS app store.",

      "Select your type of user (individual or commercial) and create an account.",
      "Sign In",
      'Click schedule request and select the "schedule drop-off" option. Select a nearby recycling company for waste for drop-off.',
      "Choose waste category/categories and input the waste quantity in bags.",
      "Choose a preferred date for waste drop-off ",
      "Confirm and submit a drop-off request",
      "Your E-wallet is credited as soon as the recycler completes the request. Earn-as-You Waste!!! Recycling made easy.",
    ],
    reverse: true,
  },
];

export const support: Array<string> = [
  "/img/1.png",
  "/img/2.png",
  "/img/3.png",
  "/img/4.png",
  "/img/5.png",
  "/img/6.png",
  "/img/7.png",
  "/img/C40 cities logo.png",
  "/img/Lagos state.png",
  "/img/Lasepa.png",
  "/img/Rethinking.png",
  "/img/alt bank.jpg",

];

export const members: Array<string> = [
  "/img/Circular network.png",
  "/img/blob_a20d1134f2 1.png",
  "/img/8.png",
  "/img/Smart-cities-world-logo-2 1.png",
  "/img/9.png",
  "/img/greenTech.png",
];

export const KPIarticles: Array<KPIData> = [
  {
    heading: "Key Performance Improvement",
    text: "Provides a focus for strategic and operational improvement, by helping us create an analytical basis for decision making and help focus attention on what matters most.",
  },
];

export const KPIFeaturesInfo: Array<FeaturesModel> = [
  {
    imgSrc: "/img/GupPickupTrash.png",
    imgAlt: "trash",
    text: "Know your daily pickup",
  },
  {
    imgSrc: "/img/Business.png",
    imgAlt: "trash",
    text: "Reduce your service cost",
  },
  {
    imgSrc: "/img/globe.png",
    imgAlt: "trash",
    text: "Provide world class service",
  },
  {
    imgSrc: "/img/Pakam.png",
    imgAlt: "trash",
    text: "Reduce organization’s carbon footprint",
  },

  {
    imgSrc: "/img/Paper.png",
    imgAlt: "trash",
    text: "Eliminate overfilling by collecting waste real time",
  },

  {
    imgSrc: "/img/route.png",
    imgAlt: "trash",
    text: "Send optimized route directly to truck drivers",
  },

  {
    imgSrc: "/img/BusinessWoman.png",
    imgAlt: "trash",
    text: "Get instant payment on geo payment scheme",
  },
  {
    imgSrc: "/img/globeEu.png",
    imgAlt: "trash",
    text: "Geo-fencing location and global positioning system",
  },
];

export const Payarticles: Array<EarnWasteModel> = [
  {
    text: "Pay-as-You Waste is a full waste management application that enables citizens to dispose of their non-recyclable and specialized waste to collectors properly. This service solves the day-to-day problem of delayed waste collection by PSP or waste management agencies. Pay-as-You Waste ensures an efficient and effective waste management system to eliminate the overflow of waste bins and environmental pollution in general. This service allows waste to be collected at just a click at an affordable pricing rate for individuals and businesses. ",
    imgAlt: "",
    imgSrc: "/img/PayDashboard.png",
    reverse: true,
    top: false,
  },
];

export const Smartarticles: Array<EarnWasteModel> = [
  {
    text: "The Pakam Smart Enforcement is an integrated environmental reporting platform that enables the public to report environmental infractions to environmental agencies faster, better, and in a unified manner. It also allows the regulatory agencies to connect with the reporting public for easy access to all infractions reported.",

    imgAlt: "Pakam Smart Reinforcement",
    imgSrc: "/img/SmartRF.png",
    reverse: false,
    top: false,
  },
];

export const payContentarticles: Array<EarnWasteContentModel> = [
  {
    imgSrc: "/img/PayPhone.png",
    imgAlt: "pickup",
    header1: "",
    header2: "How It Works",
    sub_text: [
      "Download and install the Pakam Waste Disposal Application from google play store or IOS app store",
      "Select your type of user (household or commercial) and create an account.",
      "Schedule for a waste (non-recyclable) pickup by entering the required information.",
      "A nearby collector will accept your schedule and gets routed to your location to complete the pickup.",
      "Your monthly bill invoice would be sent for payment.",
    ],

    reverse: false,
    soonchecker: true,
  },
];

export const smartContentarticles: Array<EarnWasteContentModel> = [
  {
    imgSrc: "/img/SmartPhone.png",
    imgAlt: "pickup",
    header1: "",
    header2: "How It Works",
    sub_text: [
      "Download and install the Pakam User Application from google play store or IOS app store.",
      "Create an account to gain access into the app",
      " Click on the 'report request' to make a live video of any environmental incident happening around you ",
      "Proceed to the chat room to leave a message or description of the incident",
      "Your report will be received and attended to by the registered environmental agency",
    ],
    reverse: false,
  },
];

export const kpiFeatures: Array<FeaturesModel> = [
  {
    imgSrc: "/img/globeEu.png",
    imgAlt: "trash",
    text: "Geo fencing and global positioning",
  },
  {
    imgSrc: "/img/route.png",
    imgAlt: "trash",
    text: "Send optimized routes directly to environmental agencies",
  },
  {
    imgSrc: "/img/globe.png",
    imgAlt: "trash",
    text: "Provide world class service.",
  },
  {
    imgSrc: "/img/Pakam.png",
    imgAlt: "trash",
    text: "Reduce organization carbon footprint",
  },
];

export const payKpifeatures: Array<FeaturesModel> = [
  {
    imgSrc: "/img/GupPickupTrash.png",
    imgAlt: "trash",
    text: "Provide world class service",
  },
  {
    imgSrc: "/img/GupPickupTrash.png",
    imgAlt: "trash",
    text: "Reduce organization’s carbon footprint",
  },
  {
    imgSrc: "/img/Paper.png",
    imgAlt: "trash",
    text: "Geo-fencing location and global positioning system",
  },
  {
    imgSrc: "/img/GupPickupTrash.png",
    imgAlt: "trash",
    text: "Eliminate overfilling by collecting waste real time",
  },
  {
    imgSrc: "/img/Business.png",
    imgAlt: "trash",
    text: "Send optimized route directly to truck drivers",
  },
  {
    imgSrc: "/img/Business.png",
    imgAlt: "trash",
    text: "Get instant payment on geo payment scheme",
  },
  {
    imgSrc: "/img/Paper.png",
    imgAlt: "trash",
    text: "Geo-fencing location and global positioning system",
  },
  {
    imgSrc: "/img/GupPickupTrash.png",
    imgAlt: "trash",
    text: "Eliminate overfilling by collecting waste real time",
  },
];

export const payContentArticles: Array<KPIData> = [
  {
    heading: "Key Perfomance Improvement",
    text: "Provides a focus for strategic and operational improvement, by helping us create an analytical basis for decision making and help focus attention on what matters most.",
  },
];
export const faqs: Array<TechnologyModel> = [
  {
    text: "How much are pet bottles sold per KG?",
    description:
      "Price is not fixed. It varies based on location and recyclers.",
  },

  // {
  //   text: "Can I schedule one pickup request for more than one waste category, like cans and PET Bottles?",
  //   description:
  //     "Yes, you can. The App now has a feature for you to multi-select different waste categories for one pickup or drop-off request.",
  // },

  {
    text: "Do we operate outside Lagos?",
    description:
      "Yes, we are collecting in Ogun State (Sango-ota, Agbara, Itori, and Owode)",
  },
  // {
  //   text: "How do I register on the app and setup a new schedule?",
  //   description:
  //     "Download the App from Playstore or Apple store, create an account using your Name, Phone Number and Password, verify your phone number inputting the OTP sent to the phone number used when creating the account, once you are verified login into App click on Schedule pickup button to request for a pickup when you have recyclable waste.",
  // },
  {
    text: "What’s the least number of bags one can have before requesting a pickup?",
    description:
      "The minimum number of bags you need to have before scheduling on the Pakam household app is 5. Kindly note that the bags can contain different waste categories because your waste must be sorted before a collector comes to pick up",
  },
  // {
  //   text: "Minimum KG that can be picked?",
  //   description: "5 KG",
  // },
  {
    text: "How much do I need to have in my wallet to request a payout?",
    description: "To request a payout to bank, you need to have at least 2000 naira. You can also explore our other payout options- giving to charity and purchasing a health insurance plan.",
  },

  // {
  //   text: "What is the purpose of the community chat in the app?",
  //   description:
  //     "This is to help you reach customer support within the app and connect with other app users.",
  // },

  {
    text: "How do I reach support quickly, if I have complaints or enquiries?",
    description:
    "Within the app, you can use the community chat. You can also reach our support personnel via call or Whatsapp on 09122616778.",
  },

  // {
  //   text: "Can I connect with the recycler who accepts my pickup?",
  //   description:
  //     "Yes. You can do this using the in-app chat and/or the in-app direct-to-call; click on schedule request, then schedule history. Click on a pending schedule that has been accepted by a recycler and click on the chat icon or the call icon (which redirects you to your phone's call app to continue with the call).",
  // },

  {
    text: "What are prices of all recyclables per KG?",
    description:
      "Prices vary based on location and Recycler allocated for pickup.",
  },
  {
    text: "What type of recyclable products are picked up on the Pakam Household app?",
    description:
      "Aluminum and Steel Cans, PET bottles, Plastic, Paper, Dry Cartons, Nylon, Shopping Bag, General Aluminum (E.g. roofing sheet, pots), Metal (E.g. iron, tin).",
  },
  {
    text: " Do we charge for pickups?",
    description: "No, pickup is free.",
  },
  {
    text: "Is it only Drink cans we pick up or all other types of cans?",
    description: "All cans that can be recycled.",
  },
  {
    text: "Do you accept waste like microwaves, washing machines and pumping machines?",
    description:
      "Those are E-waste, and we are working on collecting further information and this will be communicated.",
  },
  {
    text: "How do I use the Pakam Household App for waste pickup?",
    // description:
    //   "Download the App from Playstore or Apple store, create an account using your Name, Phone Number and Password, verify your phone number inputting the OTP sent to the phone number used when creating the account, once you are verified login into App click on Schedule pickup button to request for a pickup when you have recyclable waste.",

    description:
      "To get started, download the Pakam Household App from Google Playstore or Apple Store. Enter your phone number, verify with the OTP code, and create your account. \n\n Once registered, schedule a pickup by selecting waste categories, specifying the number of trash bags, pickup location, and preferred date. Confirm details, including your local government area, access area and submit your request. You will receive a notification when a collector accepts your request.",
  },
  {
    text: "Can I bring my recyclable waste myself instead of waiting for pickup?",
    description:
      "Yes. We have a drop-off feature that allows you to choose the closest drop-off center where you can weigh and drop your recycled waste.",
  },
  {
    text: "I'm not receiving OTPs on my account. What could be the issue?",
    description:
      "If you're having trouble receiving One-Time Passwords (OTPs) for your account, there are a few things you can check. First, make sure that your mobile device has a stable and active network connection. Since OTPs are typically sent via SMS, a reliable network is crucial for timely delivery. \n \n If you've confirmed that your network connection is stable and you're still not receiving OTPs, it's best to contact customer support for further assistance. You can reach out to them at 09122616778. They'll be able to help you troubleshoot the issue and get you back on track.",
  },
  {
    text: "What kind of bag should I keep the materials in?",
    description:
      "You can use the black trash bag or your rice sack.",
  },
  {
    text: "Can I schedule per bottle or cans?",
    description:
      "No. You need to bag your waste before scheduling for pick-up or drop-off.",
  },



];

export const partnershipfaqs: Array<TechnologyModel> = [
  {
    text: "How do I know what comes into my space?",
    description:
      "Your details filled in the form will be used to create an account for you, which will show the amount of waste deposited on your space and the money equivalent",
  },

  {
    text: "How do I know how much I've earned so far?",
    description:
      "Space owners will see this on the dashboard created for them; Logistics partners will see the money earned in their digital wallet",
  },

  {
    text: "Can I join if I don't have a tricycle but can ride one?",
    description:
      "Yes, you can. You will be connected to one of our logistics partners.",
  },
  {
    text: "How do I get paid?",
    description:
      "By making a request with your account on Pakam, for your money to be paid into your account.",
  },
  {
    text: "How do I sign up?",
    description:
      "Click on the sign up button for the partner type you are interested in and fill in the form.",
  },
];

export const solutions: Array<TechnologyModel> = [
  // {
  //   isTitle: true,
  //   title: "Problems we are solving",
  // },
  {
    isTitle: false,
    imgUrl: "/img/Waste sorting.png",
    title: "Disposal of Waste",
    text: "Reckless disposal of waste that clogs drainage, causes flooding, transmits diseases, harms life on land and below the sea.",
  },
  {
    isTitle: false,
    imgUrl: "/img/SheetpaperTrash.png",
    title: "Delay in waste collection",
    text: "Delay in waste collection which leads to the overflow of waste bins and comes with attendant health issues.",
  },
  {
    isTitle: false,
    imgUrl: "/img/Payment failed.png",
    title: "Payment",
    text: "Payment & cost recovery challenges in the waste management ecosystem ",
  },
  {
    isTitle: false,
    imgUrl: "/img/Inclusive Education.png",
    title: "Poor Inclusion",
    text: "Poor inclusion (gender, financial, technology, status)",
  },
  {
    isTitle: false,
    imgUrl: "/img/looking through resumes.png",
    title: "Unemployment",
    text: "Unemployement for youth and undergraduates.",
  },
];

export const impact: Array<TechnologyModel> = [
  {
    imgUrl: "/img/footprint.png",
    text: "Reduce carbon footprint with use of tricycles and smart carts",
  },
  {
    imgUrl: "/img/Wind power.png",
    text: "Empowering communities by creating decent job above the national minimum wage.",
  },
  {
    imgUrl: "/img/Proper disposal of garbage.png",
    text: "Diverting clean sorted materials from landfill & water bodies thus increasing post-consumer material value",
  },
  {
    imgUrl: "/img/Save our planet.png",
    text: "Empowering ecosystem business operators to strive even in the unavailability of resources.",
  },
  {
    imgUrl: "/img/SheetpaperTrash.png",
    text: "Securing constant post-consumer materials for aggregators. ",
  },
  {
    imgUrl: "/img/Savings.png",
    text: "Creating wealth for generating households and informal waste pickers.",
  },
];
export const SDGImageArray = [
  [
    {
      image: "/svg/no_poverty.svg",
      alt: "no_poverty",
    },
    {
      image: "/svg/reduced_inequalities.svg",
      alt: "reduced_inequalities",
    },
  ],
  [
    {
      image: "/svg/zero_hunger.svg",
      alt: "zero_hunger",
    },
    {
      image: "/svg/sustainable_cities.svg",
      alt: "sustainable_cities",
    },
  ],
  [
    {
      image: "/svg/good_health.svg",
      alt: "good_health",
    },
    {
      image: "/svg/climate_action.svg",
      alt: "climate_action",
    },
  ],
  [
    {
      image: "/svg/clean_water.svg",
      alt: "clean_water",
    },
    {
      image: "/svg/life_below.svg",
      alt: "life_below",
    },
  ],
  [
    {
      image: "/svg/decent_work.svg",
      alt: "decent_work",
    },
    {
      image: "/svg/life_on.svg",
      alt: "life_on",
    },
  ],
  [
    {
      image: "/svg/industry_innovation.svg",
      alt: "industry_innovation",
    },
    {
      image: "/svg/partnership.svg",
      alt: "partnership",
    },
  ],
];

export const SDGImageModalValue = {
  no_poverty: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641553158/E_GIF_01_dn4czh.gif",
    title: "No Poverty",
    subtitle:
      "Pakam technology solution ensures low-income communities can earn a living and uplift their economic benefit by recycling.",
  },
  reduced_inequalities: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641552717/E_GIF_10_ed6s5s.gif",
    title: "Reduced Inequalities",
    subtitle:
      "Pakam is reducing gender inequalities through partnership with aggregators by ensuring that more women are in the process of sort and earning a living from waste processing and sorting. ",
  },
  zero_hunger: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641553219/E_GIF_02_mudavg.gif",
    title: "Zero Hunger",
    subtitle:
      "Pakam technology is ensuring that anybody can feed by providing a cleaner environment.  ",
  },
  sustainable_cities: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641552780/E_GIF_11_fdrjva.gif",
    title: "Sustainable Cities & Communities",
    subtitle:
      "Pakam technology innovation and other collaborative solutions of recycling, composting, incineration, and bio-digester will change waste management forever and create a sustainable environment for all. ",
  },
  good_health: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641553269/E_GIF_03_rcg1zj.gif    ",
    title: "Good Health & Well Being",
    subtitle:
      "Pakam technology solution contributes to good health by ensuring cleaner & healthier environment through proper disposal of waste from the comfort of homes & offices.",
  },
  climate_action: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641552872/E_GIF_13_ksrzyy.gif",
    title: "Climate Action",
    subtitle:
      "Pakam Smart city innovation is helping city officials and climate NGOs to create real-time action that protects the environment and reduces climate impact.",
  },
  clean_water: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641553304/E_GIF_06_rdov8w.gif",
    title: "Clean Water & Sanitation",
    subtitle:
      "Pakam technology ensures environmental protection by reporting in real-time - indiscriminate dumping of waste in waters and drainages.",
  },
  life_below: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641552972/E_GIF_14_dxjgik.gif",
    title: "Life Below Water",
    subtitle:
      "Pakam aims to ensure waste collection from the source increases exponentially from 20% to 100% by eliminating material escape in water and oceans.",
  },
  decent_work: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641552972/E_GIF_14_dxjgik.gif",
    title: "Decent Work & Economic Growth",
    subtitle:
      "Pakam model is aimed to create jobs for the informal sector through technology innovation and collaborative incentives. The aim is to change public perception of waste pickers and waste collectors.",
  },
  life_on: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641553071/E_GIF_15_imvwea.gif",
    title: "Life On Land",
    subtitle:
      "Pakam aims at reducing and eliminating illegal dumping of waste on roadsides and dump-site, thus reducing plant, animal, and human exposure to hazardous substances.",
  },
  industry_innovation: {
    modalalt: "",
    url: " https://res.cloudinary.com/codeinstd/image/upload/v1641552593/E_GIF_09_xz2dow.gif",
    title: "Industry, Innovation & Infrastructure",
    subtitle:
      "Adopting Zero waste solution with technology innovation and collaboration, Pakam is building a sustainable industry & infrastructure that will transform people’s live, sustain our planet and generate profit for stakeholders.",
  },
  partnership: {
    modalalt: "",
    url: "https://res.cloudinary.com/codeinstd/image/upload/v1641553139/E_GIF_17_ejuc36.gif",
    title: "Partnership For The Goals",
    subtitle:
      "Pakam's core value hinges on partnership and collaboration with local & international businesses.",
  },
};

// export const values = [
//   {
//     imgUrl: ProblemSolvingTeam,
//     text: "Collaboration.",
//   },
//   {
//     imgUrl: OpenUp,
//     text: "Openness.",
//   },
//   {
//     imgUrl: MorphingGreen,
//     text: "Ownership.",
//   },
//   {
//     imgUrl: SocialNetwork,
//     text: "Social Impact",
//   },
//   {
//     imgUrl: Technology,
//     text: "Innovation.",
//   },
// ];

export const awards = [
  "/img/ITU.png",
  "/img/oxford.png",
  "/img/wamson.png",
  "/img/Waste_forum.png",
  "/img/fabe.png",
  "/img/nia.png",
  "/img/fmdo.png",
  "/img/Uplink.png",
];
export const featured = [
  "/img/Thecable.png",
  "/img/Eko_Hot.png",
  "/img/The_Guardian.png",
  "/img/Waste_forum.png",
  "/img/bhm.png",
  "/img/Western_Post.png",
  "/img/Vanguard.png",
];

export interface NewsMediaModel {
  id?: number;
  imgSrc: any;
  imgAlt: string;
  headline: string;
  subtext: string;
  link: string;
  info: { imgSrc: any; text: string }[];
  dates: any;
  bg?: boolean;
  color?: boolean;
  decoration?: boolean;
  inPage?: boolean;
  createdAt?: any;
}



export const NewsMediaArticles: Array<NewsMediaModel> = [
  {
    imgSrc: "/img/Launch.png",
    headline:
      "Groups Launch Recycling Hub In Alimosho ",
    subtext:
      "Pakam was invited to the launch of Diamond Waste Recycling Limited, the Local Economic Development Association and Community Action against Plastic Waste (CAPWS), to empower women and youths on how to turn wastes into wealth.",
    imgAlt: "Pakam",
    link: "https://guardian.ng/news/recycling-firms-move-to-empower-lagos-residents-in-transforming-wastes-into-wealth/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "January 31st, 2024",
      },
    ],
  },
  // {
  //   imgSrc: "/img/LagosWithout.png",
  //   headline:
  //     "Lagos without plastic pollution",
  //   subtext:
  //     "Two hundred thousand tons of waste produced in Nigeria ends up in the ocean every year, according to estimates by the World Economic Forum.",
  //   imgAlt: "Pakam",
  //   link: "https://www.africarivista.it/lagos-senza-inquinamento-da-plastica/206849/",
  //   info: [
  //     {
  //       imgSrc: "/img/user.png",
  //       text: "Pakam",
  //     },
  //   ],
  //   dates: [
  //     {
  //       imgSrc: "/img/Discovery.png",
  //       text: "September 14 ,2022",
  //     },
  //   ],
  // },
  // {
  //   imgSrc: "/img/pkm.png",
  //   headline:
  //     "“Pakam” the mobile application for waste recycling in Lagos",
  //   subtext:
  //     'While plastic pollution affects the soil and water bodies in Nigeria, the government of this West African country is encouraging the start-up Pakam Technology whose mobile application "Pakam" has been contributing...',
  //   imgAlt: "Pakam",
  //   link: "https://www.africarivista.it/lagos-senza-inquinamento-da-plastica/206849/",
  //   info: [
  //     {
  //       imgSrc: "/img/user.png",
  //       text: "Pakam",
  //     },
  //   ],
  //   dates: [
  //     {
  //       imgSrc: "/img/Discovery.png",
  //       text: "September 13 ,2022",
  //     },
  //   ],
  // },
  {
    imgSrc: "/img/image31-1.png",
    headline:
      "ASWOL launches Website to verify waste pickers, cart pushers in Lagos.",
    subtext:
      "As part of efforts to achieve a more sustainable eco-friendly city, the Association of Scrap and waste Pickers of Lagos (ASWOL) has launched a website to verify and integrate waste pickers,..",
    imgAlt: "Pakam",
    link: "https://tribuneonlineng.com/aswol-launches-website-to-verify-waste-pickers-cart-pushers-in-lagos/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "December 1, 2022",
      },
    ],
  },
  {
    imgSrc: "/img/image311.png",
    headline: "Expert Advocates Behavioral Change Toward Recycling ",
    subtext:
      "A environmentalist, Ms Wunmi Ogunde, has called for a positive attitude toward recycling to curb plastic pollution in the country. Ogunde, the Founder of Pakam...",
    imgAlt: "Pakam",
    link: "https://naturenews.africa/expert-advocates-behavioural-change-toward-recycling/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "September 26, 2022",
      },
    ],
  },

  {
    imgSrc: "/img/image53.png",
    headline:
      "LAWMA to commence prosecution of resident without wastebin in october",
    subtext:
      " The Lagos State Waste Management Authority (LAWMA) has pledged to commence prosecution of households without waste bins in the state from October 1, 2022.  ",
    imgAlt: "Pakam",
    link: "https://guardian.ng/news/lawma-to-commence-prosecution-of-residents-without-waste-bin-in-october/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "September 20, 2022",
      },
    ],
  },
  {
    imgSrc: "/img/image53-1.png",
    headline: "Lagos Without plastic pollution ",
    subtext:
      " Two hundred thousand tons of waste produced in Nigeria ends up in the ocean every year, according to estimates by the World Economic Forum.",
    imgAlt: "Pakam",
    link: "https://www.africarivista.it/lagos-senza-inquinamento-da-plastica/206849/",

    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "September 14, 2022",
      },
    ],
  },

  {
    imgSrc: "/img/image56.png",
    headline: '"Pakam" the mobile application for waste recycling in Lagos',
    subtext:
      'While plastic pollution affects the soil and water bodies in Nigeria, the government of this West African country is encouraging the start-up Pakam Technology whose mobile application "Pakam" has been contributing...',
    imgAlt: "Pakam",
    link: "https://www.afrik21.africa/en/nigeria-pakam-the-mobile-application-for-waste-recycling-in-lagos/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "September 13, 2022",
      },
    ],
  },
  // {
  //   imgSrc: "/img/Mark.png",
  //   headline: "Tackling Pollution Through Recycling Of waste Products",
  //   subtext: "",
  //   imgAlt: "Pakam",
  //   link: "https://www.newtelegraphng.com/tackling-pollution-through-recycling-of-waste-products/",
  //   info: [
  //     {
  //       imgSrc: "/img/user.png",
  //       text: "Pakam",
  //     },
  //   ],
  //   dates: [
  //     {
  //       imgSrc: "/img/Discovery.png",
  //       text: "Nov 08, 2022",
  //     },
  //   ],
  // },

  {
    imgSrc: "/img/image57.png",
    headline: "Waste managers in Nigeria meets, address recycling",
    subtext: "",
    imgAlt: "Pakam",
    link: "https://www.youtube.com/watch?v=PJd7CL9i0o4",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "September 12, 2022",
      },
    ],
  },

  {
    imgSrc: "/img/image28.png",
    headline:
      "LAWMA sets to make household ownership of wastebin mandatory by october 1st",
    subtext:
      "Lagos Waste Management Authority (LAWMA) has stated poise to ensure all households across the state own waste bins by October 1st as the agency celebrates the 3rd anniversary of the Lagos Recycle Initiative.",
    imgAlt: "Pakam",
    link: "https://nationaldailyng.com/lawma-set-to-make-households-ownership-of-waste-bin-mandatory-by-october-1st/?utm_source=rss&utm_medium=rss&utm_campaign=lawma-set-to-make-households-ownership-of-waste-bin-mandatory-by-october-1st",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "September 10, 2022",
      },
    ],
  },

  {
    imgSrc: "/img/image31.png",
    headline: "LAWMA marks 3rd anniversary of recyclers initiative",
    subtext:
      "Lagos Waste Management Authority (LAWMA) has celebrated the 3rd anniversary of the Lagos Recycle Initiative, launched three years ago, to introduce waste sorting at source for recycling purposes, to tackle the challenge of plastic...",
    imgAlt: "Pakam",
    link: "https://thegeniusmedia.com.ng/2022/09/10/lawma-marks-3rd-anniversary-of-lagos-recycle-initiative/?utm_source=rss&utm_medium=rss&utm_campaign=lawma-marks-3rd-anniversary-of-lagos-recycle-initiative",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "September 10, 2022",
      },
    ],
  },

  {
    imgSrc: "/img/image28-2.png",
    headline: "Lagos sets pace in turning trash to treasure",
    subtext:
      "The Lagos State Government has said it will alleviate poverty by using waste recycling processes to create jobs for women across the state",
    imgAlt: "Pakam",
    link: "https://businessday.ng/energy/article/lagos-sets-pace-in-turning-trash-to-treasure/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "June 21, 2022",
      },
    ],
  },

  {
    imgSrc: "/img/image28-1.png",
    headline: "Waste recycling will create job for women",
    subtext:
      "From creating local equipment to aid waste disposal to forming partnerships with the private sector, Lagos State is setting up a winning model on how to formalise waste...",
    imgAlt: "Pakam",
    link: "https://www.thisdaylive.com/index.php/2021/11/12/dr-dolapo-fasawe-making-case-for-the-environment-at-cop26-through-lasepa/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "March 23, 2022",
      },
    ],
  },

  {
    imgSrc: "/img/image28-3.png",
    headline: "From trash to cash",
    subtext:
      "“Many corporations have reengineered their operations to minimise their environmental impact... ",
    imgAlt: "Pakam",
    link: "https://thenationonlineng.net/from-trash-to-cash/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "November 16, 2021",
      },
    ],
  },

  {
    imgSrc: "/img/image57.png",
    headline: "Recycling enthusiast advocate change in waste disposal",
    subtext: "",
    imgAlt: "Pakam",
    link: "https://www.youtube.com/watch?v=iUyFA-aplgk",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "Oct 4, 2021",
      },
    ],
  },

  {
    imgSrc: "/img/image28-4.png",
    headline:
      "Graduate of economics wins $3,500 Ekoclimation prize for innovation in fertiliser",
    subtext:
      "Adegoke Adewale, a graduate of Economics from the University of Ado Ekiti, and his team on Friday emerged the first position winner with $3,500 prize at the final contest...",
    imgAlt: "Pakam",
    link: "https://tribuneonlineng.com/graduate-of-economics-wins-3500-ekoclimathon-prize-for-innovation-in-fertiliser/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "September 13, 2021",
      },
    ],
  },

  {
    imgSrc: "/img/Image1.png",
    headline:
      "Dr. Dolapo Fasawe: Making Case for the Environment at COP2 6 through LASEPA",
    subtext:
      "With Dr. Dolapo Fasawe as the general manager, the agency has grown in leaps and bounds since she assumed office on August 19, 2019.",
    imgAlt: "Lasepa GM",
    link: "https://www.thisdaylive.com/index.php/2021/11/12/dr-dolapo-fasawe-making-case-for-the-environment-at-cop26-through-lasepa/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "August 19, 2021",
      },
    ],
  },

  {
    imgSrc: "/img/Image2.png",
    headline:
      "Sanwo-Olu moves to create 6,000 jobs as lagos adopts waste conversion",
    subtext:
      "Lagos State is transiting from traditional waste management to conversion of waste materials – an innovation that is projected to generate 6,000 jobs.",
    imgAlt: "Babajide Sanwo-olu",
    link: "https://lagosstate.gov.ng/blog/2021/04/29/sanwo-olu-moves-to-create-6000-jobs-as-lagos-adopts-waste-conversion/",

    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "August 19, 2021",
      },
    ],
  },

  {
    imgSrc: "/img/image31-2.png",
    headline: "Lawma records, impressive to Lagos recycle initiative ",
    subtext:
      "Following the commencement of the Lagos Recycle Initiative, geared towards addressing the menace of plastic pollution in the state.",
    imgAlt: "Pakam",
    link: "hhttps://www.ekohotblog.com/2021/03/01/176149/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "August 17, 2021",
      },
    ],
  },

  {
    imgSrc: "/img/image31-3.png",
    headline: "Lawma continues to improve on waste management strategy. ",
    subtext:
      "It is very important we move forward with the Lagos Recycle Initiative and continue to evolve our waste management...",
    imgAlt: "Pakam",
    link: "https://westernpost.ng/ ",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "August 15, 2021",
      },
    ],
  },

  {
    imgSrc: "/img/image31-4.png",
    headline:
      "Lagos showcase 102 trucks, 100 locally assembled bins to tackle waste ",
    subtext:
      "“Worried by resurgence of refuse in Lagos, the State Government, yesterday, unveiled 102 new truck waste collectors and 100 bins to improve waste collection in the metropolis..",
    imgAlt: "Pakam",
    link: "https://www.thisdaylive.com/index.php/2021/11/12/dr-dolapo-fasawe-making-case-for-the-environment-at-cop26-through-lasepa/",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "August 5, 2021",
      },
    ],
  },

  // {
  //   imgSrc: "/img/Mark1.png",
  //   headline:
  //     "Mr. Macaroni, PwC Nigeria, Others receive Awards at the Nigeria Innovation Summit 2022",
  //   subtext: "",
  //   imgAlt: "Pakam",
  //   link: "https://techbuild.africa/mr-macaroni-awards-nigeria-innovation-summit/",
  //   info: [
  //     {
  //       imgSrc: "/img/user.png",
  //       text: "Pakam",
  //     },
  //   ],
  //   dates: [
  //     {
  //       imgSrc: "/img/Discovery.png",
  //       text: "Nov 08, 2022",
  //     },
  //   ],
  // },
];

export const PressRelase: Array<NewsMediaModel> = [
  {
    id: 1,
    imgSrc: "/img/60.png",
    headline: "Pakam announces outreach partnership with Trucka",
    subtext:
      " PAKAM Technology Limited is excited to announce our outreach partner, Trucka...",
    imgAlt: "Pakam",
    link: "",
    info: [
      {
        imgSrc: "/img/LightUser.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/LightDiscovery.png",
        text: "December 22,2022",
      },
    ],
    bg: true,
    color: true,
    decoration: true,
    inPage: true,
  },

  {
    id: 2,
    imgSrc: "/img/20.png",
    headline: "Pakam accepted as a member of Greentech Alliance",
    subtext:
      "We are glad to have been accepted as a member of greentech alliance....",
    imgAlt: "Pakam",
    link: "",
    info: [
      {
        imgSrc: "/img/LightUser.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/LightDiscovery.png",
        text: "December 12 ,2022",
      },
    ],
    bg: true,
    color: true,
    decoration: true,
    inPage: true,
  },

  {
    id: 3,
    imgSrc: "/img/30.png",
    headline: "Pakam announces outreach partnership with DeliveaNG",
    subtext:
      "“At Pakam, our mission is to create waste scarcity, & make sense of waste by connecting waste...”",
    imgAlt: "Pakam",
    link: "",
    info: [
      {
        imgSrc: "/img/LightUser.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/LightDiscovery.png",
        text: "December 11 ,2022",
      },
    ],
    bg: true,
    color: true,
    decoration: true,
    inPage: true,
  },

  {
    id: 4,
    imgSrc: "/img/40.png",
    headline:
      "Pakam announces strategic partnership with I-cell Multimedia Ltd and Vector Telecom Services Ltd",
    subtext:
      "The development and deployment of the USSD platform have become very necessary...”",
    imgAlt: "Pakam",
    link: "",
    info: [
      {
        imgSrc: "/img/LightUser.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/LightDiscovery.png",
        text: "December 11 ,2022",
      },
    ],
    bg: true,
    color: true,
    decoration: true,
    inPage: true,
  },

  {
    id: 5,
    imgSrc: "/img/GRealease.png",
    headline: "Pakam announces strategic partnership with Arup",
    subtext:
      " Pakam technology has entered a strategic partnership with Arup. The collaboration originated from the need to build circularity around waste for a sustainable environment and will help both parties to support sustainable agenda...",
    imgAlt: "Pakam",
    link: "",
    info: [
      {
        imgSrc: "/img/LightUser.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/LightDiscovery.png",
        text: "February 9th ,2022",
      },
    ],
    bg: true,
    color: true,
    decoration: true,
    inPage: true,
  },
];

export const InterviewData: Array<NewsMediaModel> = [
  {
    imgSrc: "/img/Interview1.png",
    headline:
      "Cleaning up Lagos: How a startup is turning waste into a scarce resources that you and i will beg for one day.",
    subtext: "",
    imgAlt: "Pakam",
    link: "https://www.linkedin.com/pulse/cleaning-up-lagos-how-nigerian-startup-turning-waste-/?trackingId=3OGTtNddjUDMAKuE8%2BzXhw%3D%3D",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "October,2022",
      },
    ],
  },

  {
    imgSrc: "/img/Interview2.png",
    headline: "Recycle and Make Money",
    subtext: "",
    imgAlt: "Pakam",
    link: "https://www.youtube.com/watch?v=5e8S_93xk1g",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "June 30,2021",
      },
    ],
  },
  {
    imgSrc: "/img/Interview3.png",
    headline: "LAWMA Rewards Recyclers, Encourages Others",
    subtext: "",
    imgAlt: "Pakam",
    link: "https://www.youtube.com/watch?v=6BEYg3-g47I",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "May 30,2021",
      },
    ],
  },
];

export const PublicationData: Array<NewsMediaModel> = [
  {
    imgSrc: "/img/Pub2.png",
    headline: "Waste management in Lagos: The realities?",
    subtext: "",
    imgAlt: "Pakam",
    link: "https://podcasts.google.com/feed/aHR0cHM6Ly9hbmNob3IuZm0vcy83N2RhMzE2OC9wb2RjYXN0L3Jzcw/episode/NGRjMDQzM2QtN2M1MS00NDBhLWJlMjctOGFiZTRiNzA4YWE5?ep=14",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "March 11,2022",
      },
    ],
  },
  {
    imgSrc: "/img/Pub3.png",
    headline: " Circularity in Nigeria",
    subtext: "",
    imgAlt: "Pakam",
    link: "https://www.youtube.com/watch?v=nj0maJU-0vA",
    info: [
      {
        imgSrc: "/img/user.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/Discovery.png",
        text: "July 30,2021",
      },
    ],
  },
  {
    imgSrc: "/img/Pub1.png",
    headline: " Plastic polution: Can recycling help save Lagos?",
    subtext: "",
    imgAlt: "Pakam",
    link: "https://www.thisdaylive.com/index.php/2021/11/12/dr-dolapo-fasawe-making-case-for-the-environment-at-cop26-through-lasepa/",
    info: [
      {
        imgSrc: "/img/LightUser.png",
        text: "Pakam",
      },
    ],
    dates: [
      {
        imgSrc: "/img/LightDiscovery.png",
        text: "July 20,2021",
      },
    ],
    // bg: true,
    // color: true,
    // decoration: false,
  },
];

export interface ReviewData {
  name: string;
  role: string;
  content: string;
}

export const Review: Array<ReviewData> = [
  {
    name: "Hodok Orok",
    role: "Household User",
    content:
      "I had these two bags of plastic waste in my backyard for quite a long, long time now hoping to get a local recycler to come and pick them up. Then i heard of @pakam-ng, an initiative of the Lagos State Govt. @moelagos @lawma-gov. I called Mr. T @adewole-taiwo who explained to me how the Pakam app works and last week, I requested a pick-up and the response was swift.",
  },

  {
    name: "Micheal Ogunleye",
    role: "Household User",
    content: "I recommend for every household",
  },

  {
    name: "Micheal Ogunleye",
    role: "Household User",
    content:
      "Once you schedule they will always find you to pickup. Very fast, efficient and reliable. I recommend for every household.",
  },

  {
    name: "Godwin Felix",
    role: "Household User",
    content: "Good App",
  },

  {
    name: "Taiwo Agbesin",
    role: "Household User",
    content: "Good App, it satifies my action",
  },

  {
    name: "Jadesola Samuel",
    role: "Household User",
    content:
      "This is the best sustainable app I’ve ever seen. Quality services. Keep up the good work",
  },

  {
    name: "Godwin Felix",
    role: "Household User",
    content: "A very welcoming innovation",
  },

  {
    name: "Oluwatobi Asala",
    role: "Household User",
    content: "Superb",
  },
];

export const ExpertReviewData: Array<ReviewData> = [
  {
    name: "Poonam Watine",
    role: "Knowledge Curation Specialist at WEF",
    content:
      "Great understanding and specific experience on the waste sector in Nigeria. The application also seems very user-friendly and innovative.",
  },

  {
    name: "Haidy Ear-Dupuy",
    role: "Senior Social Development  & CCADB",
    content:
      "Pakam shows understanding of the problem and have proposed a technological solution for linking the workers who collect plastics. The solution can potentially work in Indonesia, it may just need some adaptation to the situation in the country. ",
  },

  {
    name: "Oskar Orling",
    role: "Entrepreneur,  Member of the WEF expert network",
    content:
      "Well limited innovation with a big impact in area where it is well needed.",
  },

  {
    name: "Zoe Lenkiewicz",
    role: "Technical & social specialist in global waste & resources",
    content:
      "After learning about PAKAM from Adeleye Odebunmi, I am not surprised this is gaining so much attention and support. It is a very well thought through and impactful platform-as-a service, offering a win-win-win for waste pickers, resident and local government.Congrats!",
  },

  {
    name: "Hammed olowonyo",
    role: "CEO PROCYCLE",
    content: "Pakam is the future, very interactive and seamless.",
  },

  // {
  //   name: "Jadesola Samuel",
  //   role: "Household User",
  //   content:
  //     "This is the best sustinable app I’ve ever seen. Quality services. Keep up the good work",
  // },
];

export const citizens = {
  id: "0",
  img: "/img/Group 626022.png",
  imgStart: "",
  header: "Citizens.",
  // title: "",
  text: "Pakam aims to improve citizens engagement in  waste management through waste on-demand solutions and incentivisation, allowing citizens the opportunity to make sense of their waste either through our Earn-as-You Waste (Pakam Recycling) or Pay-as-You Waste (Pakam Waste Disposal) eco-system application.    ",
};

export const businessOperators = {
  id: "1",
  img: "/img/Group 626067.png",
  imgStart: "start",
  title: "Business Operators.",
  text: "Pakam aims to enable the waste management ecosystem businesses by optimizing their collections and operations to ensure a seamless business transaction flow. We help business operators to monitor their assets, track material collection, connect waste materials from generators to collectors, pre-processor, processors, and treatment facilities, e.t.c. We also offer real-time business insight for businesses to operate in a better and competitive environment. Our solution allows businesses in the recycling eco-system to build a stable and sustainable material collection flow directly from source thus increasing value for materials across the value chain. Furthermore, we are creating value for businesses to ensure a seamless cash flow for materials transactions. Our solution has  created a unique  avenue for collectors within the ecosystem to get access to necessary support such as logistics, infrastures etc from investors, industry captains and the government.",
};
export const agencies = {
  id: "2",
  img: "/img/group-afro-americans-working-together 1.png",
  imgStart: "",
  header: "Agencies.",
  text: "Pakam is proffering solutions for the government and its agencies to build data for insight, track inclusion and build resilience through our smart enforcement and reporting platform. Through our analytic data insight, we are able to empower smart city solutions. Through Pakam, agencies are able to ensure proper guidance for optimum performance within the ecosystem. We are aimed at providing avenues for government agencies  to play an active role in smart city development with system driven innovations. ",
};

export const contact = [
  {
    title: "Send Us a message ",
    text: "We would love to talk about how we can work together ",
    link: "Contact Support",
    linkto: "/contact",
  },
  {
    title: "Have Question? ",
    text: "Explore our help desk for quick answers to your question  ",
    link: "View help desk",
    linkto: "/faqs",
  },
  {
    title: "Media and Press ",
    text: "Get the lastest news and updates about our company and products   ",
    link: "explore our news page",
    linkto: "/news",
  },
];

export const contact2 = [
  {
    title: "Office Addresss",
    text: "347, Ikorodu road, Maryland, Lagos State.",
    // links: ["/img/facebook.png", "/img/instagram.png", "/img/twitter.png"],
  },
  {
    title: "Support Mail ",
    text: "info@pakam.ng",
    // links: ["/img/facebook.png", "/img/instagram.png", "/img/twitter.png"],
  },
  {
    title: "Phone Number",
    text: "+234 - 912 261 6778",
    // text: "+234 - 912 261 6778 \n +234 - 708 664 6637",
    // links: ["/img/facebook.png", "/img/instagram.png", "/img/twitter.png"],
  },
  {
    title: "Socials",
    links: [
      {
        imgSrc: "/img/facebookGreen.png",
        imgLink: "https://web.facebook.com/people/Pakam/100067730415458/",
      },

      {
        imgSrc: "/img/instagramGreen.png",
        imgLink: "https://www.instagram.com/pakam_ng/?igshid=YmMyMTA2M2Y%3D",
      },

      {
        imgSrc: "/img/twitterGreen.png",
        imgLink:
          "https://twitter.com/pakamnigeria?s=21&t=uMsG_wAOUm9bn0s5lDb9YQ",
      },
      {
        imgSrc: "/img/whatsappGreen.png",
        imgLink:
          "https://api.whatsapp.com/message/7U3WYHMHI3HPF1?autoload=1&app_absent=0",
      },
      {
        imgSrc: "/img/pajamas_linkedin-1.png",
        imgLink: "https://www.linkedin.com/company/pakam-technology-limited/",
      },
    ],
    // links: [
    //   "/img/facebookGreen.png",
    //   "/img/instagramGreen.png",
    //   "/img/twitterGreen.png",
    //   "/img/whatsappGreen.png",
    // ],
  },
];

export const pressItems = [
  {
    id: 1,
    date: "December 22nd, 2022",
    heading: "Pakam announces outreach partnership with Trucka",
    paragraph1:
      "PAKAM Technology Limited is excited to announce our outreach partner, Trucka is a mini-truck hailing e-logistics company that provides on-demand share logistic to businesses and individuals.",
    Paragraph2:
      " At Pakam, our mission is to create waste scarcity, & make sense of waste by connecting waste generators to collectors and to aggregators while promoting smart transaction and building data for insight. ",
    paragraph3:
      "One of the biggest challenge facing waste management today, is the lack of collection infrastructure, specifically logistic. Partnership with Trucka will fill in the gap and make up for the disconnect between generators, collectors & aggregators.",
    paragraph4:
      "Trucka will make it easy for household to sell off the recyclable waste on time, while guaranteeing aggregators constant feedstock on pakam marketplace platform.",
    paragraph5:
      "With this partnership, Pakam will continue to consolidate on its pioneering of an enduring ecosystem, with focus on value-chain operation wherein different parts of a larger ecosystem come together to create a sustainable solution for the people, planet and Profits.",
    paragraph6:
      "Next steps We will expand this partnership to reach more generators and reduce their waiting time to transact their waste on Pakam. while also ensuring that aggregators feedstock increases, to guarantee their supply of materials to recyclers.",
    paragraph7:
      "Version 2.0 We launched Pakam in 2021 with Pakam recycling (Earn as you waste), Since then we have developed and increase our business portfolio to include, Pakam waste collection (Pay as you waste) & Pakam smart enforcement. In the last quarter of 2022, we improved & unveiled Pakam 2.0 Recycling to include integrations with Fin-tech, Edu-tech, Insur-tech, Telcom & media, live reporting & informal sector (waste pickers) interface.",
    paragraph8:
      "If you’d like to talk to us about business partnerships. Kindly contact us here. Install PAKAM on Google Play Store & Apple store or look us up on our website.",
    image: "/img/40.png",
  },

  {
    id: 2,
    date: "December 12th, 2021",
    heading: "Pakam has been accepted as a member of Green-tech alliance",
    paragraph1:
      "We are glad to have been accepted as a member of greentech allaince. The green-tech alliance is a community of 1000+ Greentech companies, advisors, top tier VCs, media and experts",
    image: "/img/20.png",
  },

  {
    id: 3,
    date: "December 11th, 2021",
    heading: "Pakam announces outreach partnership with DeliveaNG",
    paragraph1:
      "PAKAM Technology Limited is excited to announce our outreach partner, DeliveaNG Logistics DeliveaNG Logistics is a tech-enabled organisation that focuses on the fast and effective movement of people, products and services.",
    Paragraph2:
      "  At Pakam, our mission is to create waste scarcity, & make sense of waste by connecting waste generators to collectors and to aggregators while promoting smart transaction and building data for insight.",
    paragraph3:
      "   One of the biggest challenge facing waste management today, is the lack of collection infrastructure, specifically logistic. Partnership with DeliveaNG will fill in the gap and make up for the disconnect between generators, collectors & aggregators.",
    paragraph4:
      "DeliveaNG will make it easy for household to sell off the recyclable waste on time, while guaranteeing aggregators constant feedstock on pakam marketplace platform.",
    paragraph5:
      "With this partnership, Pakam will be pioneering an enduring ecosystem, with focus on value-chain operation wherein different parts of a larger ecosystem come together to create a sustainable solution for the people, planet and Profits.",
    paragraph6:
      "Next steps We will expand this partnership to reach more generators and reduce their waiting time to transact their waste on Pakam. while also ensuring that aggregators feedstock increases, to guarantee their supply of materials to recyclers.",
    paragraph7:
      "Version 2.0 We launched Pakam in 2021 with Pakam recycling (Earn as you waste), Since then we have developed and increase our business portfolio to include, Pakam waste collection (Pay as you waste) & Pakam smart enforcement. In the last quarter of 2022, we improved & unveiled Pakam 2.0 Recycling to include integrations with Fin-tech, Edu-tech, Insur-tech, Telcom & media, live reporting & informal sector (waste pickers) interface.",
    paragraph8:
      "If you’d like to talk to us about business partnerships. Kindly contact us here. Install PAKAM on Google Play Store & Apple store or look us up on our website. New-frontier team.",
    image: "/img/30.png",
  },

  {
    id: 4,
    date: "December 11th, 2021",
    heading:
      "Pakam announces strategic partnership with I-cell Multimedia Ltd and Vector Telecom Services Ltd",
    paragraph1:
      "PAKAM Technology Limited is excited to announce a strategic partnership with I-Cell Multimedia Ltd & Vector Telecom Services Ltd to develop, deploy, and manage the Pakam USSD platform. I-cell Multimedia Ltd is a foremost NCC aggregator company in Nigeria while Vector Telecom Services Ltd is a foremost VAS provider company with NCC and together, they are teaming up with us at Pakam.",
    Paragraph2:
      " The development and deployment of the USSD platform have become very necessary for us to ensure greater inclusion in the management of waste, especially amongst technological underserve communities across Nigeria..",
    paragraph3:
      "  Our focus is to ensure the “Pakam USSD users” experience is not different from the “Pakam Mobile App users” experience. For example, the new fin-tech integration on the Mobile App would also be one of the many features included in the USSD platform.",
    paragraph4:
      "Pakam Technology Limited was officially launched in 2021 with its signature product “Pakam recycling” (Earn as you waste). With our recent product improvement, Pakam recycling will be integrating Fin-tech, Edu-tech, Insur-tech, Telcom & media, live reporting & informal sector (waste pickers) interface into its operations. On the other end we continue to deploy and improve on other business portfolio such as Pakam waste collection (Pay as you waste) & Pakam smart enforcement.",
    paragraph5:
      "If you’d like to talk to us about business partnerships. Kindly contact us here.",
    paragraph6:
      "Install PAKAM on Google Play Store & Apple store or look us up on our website.New-frontier team..",
    image: "/img/40.png",
  },

  {
    id: 5,
    date: "February 9th, 2021",
    heading: "Pakam announces strategic partnership with Arup",
    paragraph1:
      "Pakam technology has entered a strategic partnership with Arup. The collaboration originated from the need to build circularity aroundwaste for a sustainable environment and will help both parties to support sustainable agenda through strengthening of capacity for recycling, composting, up-cycling, and reuse of waste materials.",
    Paragraph2:
      "  Pakam is a next-generation unified waste management collection software & reporting platform start-up, enabling circularity & responsible disposal of waste on an Earn-as-You Waste basis to achieve a zero-waste circular economy. Pakam connects waste from the generating public and/or businesses to waste companies through waste collector in real-time, activates transactions in real-time, and generates actionable insight reports in real-time. Pakam leverages end-to-end real-time data intelligence across the waste collection ecosystem that guarantees automation, transparency, incentivisation, and material traceability",
    paragraph3:
      " Arup is a British multinational professional services firm headquartered in London which provides design, engineering, architecture, planning, and advisory services across every aspect of the built environment. With presence across 35 countries around the world. Arup has participated in projects in over 160 countries with sustainability at the core of its business.",
    paragraph4:
      "This partnership is key to building an impactful and sustainable solution for communities recycling, composting, and reusing with the aim to create circular economy, communities inclusion, and climate resilience.",
    paragraph5:
      "This collaboration is starting immediately, as it will increase the credibility of Arup and Pakam in the global push to solving waste management challenges, and climate change.",
    paragraph6:
      "Initially, the partnership will revolve around support for recycling collection. With time, the companies will also collaborate on building collection infrastructure to support the ecosystem.",
    image: "/img/PressRelease.png",
  },
];

export const Dates = [
  {
    imgSrc: "/img/LightDiscovery.png",
    text: "July 20,2021",
  },
];

export const householdSupport = [
  {
    link: "",
    id: 1,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676638137/Website%20Support%20Folder/Household%20User/How_to_sign_up_on_the_Pakam_household_application_o3j9d5.jpg",
    title: "How to sign up on the Pakam household application",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",
    steps: [
      {
        title: "Step 1:",
        info: "Step 1 :Download the Pakam household application from Google play store (android) or Apple store (iOS)",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894435/Website%20Support%20Folder/Household%20User/How%20to%20sign%20up%20on%20the%20Pakam%20household%20application/Step_1_w6ddob.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2 : Open the Pakam application and click on “get started” to create an account.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894435/Website%20Support%20Folder/Household%20User/How%20to%20sign%20up%20on%20the%20Pakam%20household%20application/Step2_rzyysj.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3 : Fill in the fields and select the organization you’re a collector for.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894434/Website%20Support%20Folder/Household%20User/How%20to%20sign%20up%20on%20the%20Pakam%20household%20application/Step_3_ulelrg.jpg",
      },

      {
        title: "Step 4:",
        info: "Step 4 : Verify the phone number used in step 3 by entering the 4 digit code sent to that number via sms and clicking on verify code.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894436/Website%20Support%20Folder/Household%20User/How%20to%20sign%20up%20on%20the%20Pakam%20household%20application/Step_4_tz3xfu.jpg",
      },

      {
        title: "Step 5:",
        info: "Step 5 : After the number has been successfully verified, you would be required to login.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894436/Website%20Support%20Folder/Household%20User/How%20to%20sign%20up%20on%20the%20Pakam%20household%20application/Step_5_ju2xxj.jpg",
      },
    ],
  },
  {
    link: "",
    id: 2,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676638137/Website%20Support%20Folder/Household%20User/How_to_schedule_a_pickup_bwtf2o.jpg",
    title: "How to schedule a pick up",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1 : Log in to your account on the household app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894350/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_1_pjhjza.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2 : Click on schedule request on the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894350/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_2_alown2.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on schedule pick up and do the following",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894350/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_3_nd2c7k.jpg",
      },

      {
        title: "A",
        info: "A: Select the waste categories you would want to be picked up. Note that: you can select more than one.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894351/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_3A_rv26zt.jpg",
      },

      {
        title: "B:",
        info: "B: Indicate the quantity of wastes to be picked up, in bags.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894351/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_3B_dws7bg.jpg",
      },

      {
        title: "C:",
        info: "C: Enter the pick up address. You can select the correct one from the pop-up list.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894349/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_3C_jcqrpe.jpg",
      },

      {
        title: "D:",
        info: "D: Select the date you want for your wastes to be picked up. You can choose a different date from the one already selected.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894348/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_3D_hpl5bf.jpg",
      },

      {
        title: "E:",
        info: "E: You can choose to set a reminder and if you want to be called when the collector arrives at the address.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894348/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_3E_jailbf.jpg",
      },

      {
        title: "Step 4:",
        info: "Step 4: Confirm all the details you have entered and schedule your pickup",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894349/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_4_qioy01.jpg",
      },
    ],
  },
  {
    link: "",
    id: 3,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676639166/Website%20Support%20Folder/Household%20User/How_to_schedule_a_drop_off_bxponx.jpg",
    title: "How to schedule a drop off",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the household app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894168/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20drop%20off/Step_1_aqv2zy.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on schedule request on the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894169/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20drop%20off/Step_2_jlkiz1.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on schedule drop off.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894169/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20drop%20off/Step_3_lvovls.jpg",
      },

      {
        title: "Step 4",
        info: "Step 4: After the application is done scanning for recycling companies close to you.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894169/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20drop%20off/Step_4_oyqzst.jpg",
      },

      {
        title: "step 4A",
        info: "A: Select your preferred location.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894170/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20drop%20off/Step_4A_k9cufd.jpg",
      },

      {
        title: "Step 4B:",
        info: "B: Select the waste categories to be dropped off.Note that: you can select more than one.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894171/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20drop%20off/Step_4B_rtesf1.jpg",
      },

      {
        title: "Step 4C:",
        info: "C: Indicate the quantity of waste to be dropped off, in bags.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894169/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20drop%20off/sTEP_4c_ymscaf.jpg",
      },

      {
        title: "4D",
        info: "D: Select the date for drop-off. You can choose a different date from the one already selected.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894169/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20drop%20off/Step_4D_yjcqzw.jpg",
      },

      {
        title: "Step 4E:",
        info: "E: Confirm your schedule by clicking on the “continue” button.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894168/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20drop%20off/Step_4E_iywnoo.jpg",
      },
    ],
  },
  {
    link: "",
    id: 4,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676639206/Website%20Support%20Folder/Household%20User/How_to_delete_a_pending_or_missed_schedule_dg7qvc.jpg",
    title: "How to delete a pending or missed schedule",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the household app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893817/Website%20Support%20Folder/Household%20User/How%20to%20delete%20a%20pending%20or%20missed%20schedule/Step_1_jkoism.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on schedule request on the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893818/Website%20Support%20Folder/Household%20User/How%20to%20delete%20a%20pending%20or%20missed%20schedule/Step_2_y2wjwm.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on schedule history.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893819/Website%20Support%20Folder/Household%20User/How%20to%20delete%20a%20pending%20or%20missed%20schedule/Step_3_pxwhcb.jpg",
      },

      {
        title: "Step 4",
        info: "Step 4: Click on the pending tab, where you see all your pending schedules or Click on the missed tab. to see your schedules that have been missed.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893816/Website%20Support%20Folder/Household%20User/How%20to%20delete%20a%20pending%20or%20missed%20schedule/Step_4_xo9w2q.jpg",
      },

      {
        title: "step 5",
        info: "Step 5: Click on the schedule that was missed or pending ",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893817/Website%20Support%20Folder/Household%20User/How%20to%20delete%20a%20pending%20or%20missed%20schedule/Step_5_udzr1g.jpg",
      },

      {
        title: "Step 6:",
        info: "Step 6: Click the “delete” button",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893819/Website%20Support%20Folder/Household%20User/How%20to%20delete%20a%20pending%20or%20missed%20schedule/Step_6_vzirrn.jpg",
      },

      {
        title: "Step 7:",
        info: "Step 7: You’ve successfully deleted your missed schedule.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893817/Website%20Support%20Folder/Household%20User/How%20to%20delete%20a%20pending%20or%20missed%20schedule/Step_7_kverb0.jpg",
      },
    ],
  },
  {
    link: "",
    id: 5,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676639166/Website%20Support%20Folder/Household%20User/How_to_reschedule_a_missed_schedule_xf0dkq.jpg",
    title: "How to reschedule a missed schedule",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the household app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894127/Website%20Support%20Folder/Household%20User/How%20to%20reschedule%20a%20missed%20schedule/Step_1_zyhsxl.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on schedule request on the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894127/Website%20Support%20Folder/Household%20User/How%20to%20reschedule%20a%20missed%20schedule/Step_2_sbxf82.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on schedule history.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894126/Website%20Support%20Folder/Household%20User/How%20to%20reschedule%20a%20missed%20schedule/Step_3_ghahgd.jpg",
      },

      {
        title: "Step 4",
        info: "Step 4: Click on the missed tab. to see your schedules that have been missed.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894126/Website%20Support%20Folder/Household%20User/How%20to%20reschedule%20a%20missed%20schedule/Step_4_yehukt.jpg",
      },

      {
        title: "step 5",
        info: "Step 5: Click on the schedule that was missed and click on reschedule.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894126/Website%20Support%20Folder/Household%20User/How%20to%20reschedule%20a%20missed%20schedule/Step_5_srth8x.jpg",
      },

      {
        title: "Step 6:",
        info: "Step 6: Repeat the same steps for scheduling a pick up",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894127/Website%20Support%20Folder/Household%20User/How%20to%20reschedule%20a%20missed%20schedule/Step_6_rv32s6.jpg",
      },
    ],
  },
  {
    link: "",
    id: 6,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676638137/Website%20Support%20Folder/Household%20User/How_to_sign_up_on_the_Pakam_household_application_o3j9d5.jpg",
    title: "How to request a payout to your bank account or to charity",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the household app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894350/Website%20Support%20Folder/Household%20User/How%20to%20schedule%20a%20pick%20up/Step_1_pjhjza.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on wallet on the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893318/Website%20Support%20Folder/Household%20User/How%20to%20request%20a%20payout%20to%20your%20bank%20account%20or%20to%20charity/Step_2_ly9ehs.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on payout.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893319/Website%20Support%20Folder/Household%20User/How%20to%20request%20a%20payout%20to%20your%20bank%20account%20or%20to%20charity/Step_3_kcdjeq.jpg",
      },

      {
        title: "Step 4",
        info: "Step 4: Click on “direct to bank” if you want it in your bank account or Click on “direct to charity” if you want to give the money to charity.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893317/Website%20Support%20Folder/Household%20User/How%20to%20request%20a%20payout%20to%20your%20bank%20account%20or%20to%20charity/step_4_eoz3iu.jpg",
      },

      {
        title: "Step 5:",
        info: "Step 5: For bank payment, select your bank and enter your account number.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893318/Website%20Support%20Folder/Household%20User/How%20to%20request%20a%20payout%20to%20your%20bank%20account%20or%20to%20charity/Step_5_uy4ulr.jpg",
      },

      {
        title: "Step 5A",
        info: "A: Click on proceed and confirm the name that comes up and click on payout.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893319/Website%20Support%20Folder/Household%20User/How%20to%20request%20a%20payout%20to%20your%20bank%20account%20or%20to%20charity/Step_5A_jao2xv.jpg",
      },

      {
        title: "Step5 B:",
        info: "B: Verify the action, by entering the 4 digit code sent to your phone number and click confirm payout.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893319/Website%20Support%20Folder/Household%20User/How%20to%20request%20a%20payout%20to%20your%20bank%20account%20or%20to%20charity/Step_5B_vbup4a.jpg",
      },

      {
        title: "Step 6:",
        info: "Step 6: For payment to charity, Select the charity organization you would like your money to be paid to.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893319/Website%20Support%20Folder/Household%20User/How%20to%20request%20a%20payout%20to%20your%20bank%20account%20or%20to%20charity/step6_nfqu4d.jpg",
      },

      {
        title: "Step 6A:",
        info: "A: Enter the amount you want them to be paid.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893321/Website%20Support%20Folder/Household%20User/How%20to%20request%20a%20payout%20to%20your%20bank%20account%20or%20to%20charity/Step_6A_usyyos.jpg",
      },

      {
        title: "Step 6B:",
        info: "B: Confirm your donation by clicking on “pay“, on the modal that pops up.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676893319/Website%20Support%20Folder/Household%20User/How%20to%20request%20a%20payout%20to%20your%20bank%20account%20or%20to%20charity/Step_6B_rn0zfb.jpg",
      },
    ],
  },
  {
    link: "",
    id: 7,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676639206/Website%20Support%20Folder/Household%20User/How_to_log_out_from_the_household_application_qcos6w.jpg",
    title: "How to log out from the household  application",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: While in the app, click on account at the bottom of the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894696/Website%20Support%20Folder/Household%20User/How%20to%20log%20out%20from%20the%20household%20application/Step_1_ipnrjk.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on logout",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894696/Website%20Support%20Folder/Household%20User/How%20to%20log%20out%20from%20the%20household%20application/Step_2_pcfa5d.jpg",
      },
    ],
  },
  {
    link: "",
    id: 8,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676638137/Website%20Support%20Folder/Household%20User/How_to_see_your_schedule_history_fhigws.jpg",
    title: "How to see your schedule history",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the household app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894910/Website%20Support%20Folder/Household%20User/How%20to%20see%20your%20schedule%20history/Step_1_w8poux.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on schedule request on the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894912/Website%20Support%20Folder/Household%20User/How%20to%20see%20your%20schedule%20history/Step_2_fgo2pr.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on schedule history.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894911/Website%20Support%20Folder/Household%20User/How%20to%20see%20your%20schedule%20history/Step_3_xrt936.jpg",
      },

      {
        title: "Step 4",
        info: "Step 4: Click on “Pending” to see all schedules (both pickup and drop off) that have not been picked up from you.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894911/Website%20Support%20Folder/Household%20User/How%20to%20see%20your%20schedule%20history/Step_4_qrqz95.jpg",
      },

      {
        title: "step 4A",
        info: "A: To see only drop-off, click on “All schedules” just below the pending tab and click on drop-off schedules.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894910/Website%20Support%20Folder/Household%20User/How%20to%20see%20your%20schedule%20history/Step%204A.jpg",
      },

      {
        title: "Step 4B:",
        info: "B: To see only pick-up, click on “All schedules” just below the pending tab and click on pick-up schedules.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1677145413/Website%20Support%20Folder/Household%20User/How%20to%20see%20your%20schedule%20history/Step_4B_wtcwgd.jpg",
      },

      {
        title: "Step 4C:",
        info: "C: You can click on any of the pending pickup schedules to see who has accepted your schedule.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894911/Website%20Support%20Folder/Household%20User/How%20to%20see%20your%20schedule%20history/Step_4C_hc5bim.jpg",
      },

      {
        title: "Step 4D:",
        info: "D: You can also send a message or call the collector..",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894912/Website%20Support%20Folder/Household%20User/How%20to%20see%20your%20schedule%20history/Step_4D_leotyk.jpg",
      },

      {
        title: "Step 5:",
        info: "Step 5: Click on “Completed/ Missed” to see your schedules that have been completed or missed.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676894910/Website%20Support%20Folder/Household%20User/How%20to%20see%20your%20schedule%20history/Step_5_fxt5lf.jpg",
      },
    ],
  },
  {
    link: "",
    id: 9,
    imgUrl: "/img/How_to_purchase_en2g6f.jpg",
    title: "How to Purchase a health Plan",
    subtitle: "Modified on: Tue, 16 May, 2023",
    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Download the Pakam Household Application from Google play store (android) or Apple store (iOS)",
        imgUrl: "/img/Step_1_nafgij.jpg",
      },
      {
        title: "Step 2:",
        info: 'Step 2: Create an account by clicking on "get started". Fill in the fields with your details and select your user type- individual or commercial.',
        imgUrl: "/img/Step2_1_fpcsma.jpg",
      },
      {
        title: "Step 3:",
        info: "Step 3: Verify the phone number entered with the OTP that has been sent via sms to that number.",
        imgUrl: "/img/Step_3_spkt6b.jpg",
      },
      {
        title: "Step 4",
        info: "Step 4: Once your phone number has been verified, log in with your registered phone number and password which launches you into the home page.",
        imgUrl: "/img/Step_4_sh5b5b.jpg",
      },
      {
        title: "Step 5:",
        info: "Step 5: To be able to purchase insurance plans on the Pakam app, you must have made pickup or drop-off requests for your recyclable waste and have the monetary value in your wallet.",
        imgUrl: "/img/Step_5_jgcjex.jpg",
      },

      {
        title: "Step 6:",
        info: "Step 6: Click on the wallet at the bottom of your screen, then click on payout.",
        imgUrl: "/img/Step6_yhedlo.jpg",
      },
      {
        title: "Step 7:",
        info: "Step 7: Click on the option to purchase a health insurance plan",
        imgUrl: "/img/Step7_frbz7n.jpg",
      },
      {
        title: "Step 8:",
        info: "Step 8: Select the plan you will like to purchase.",
        imgUrl: "/img/Step8_jpsqzc.jpg",
      },
      {
        title: "Step 9:",
        info: "Step 9: Go through the information for the plan selected and proceed to purchase by clicking on the button at the bottom of the page.",
        imgUrl: "/img/Step9_ngvsek.jpg",
      },
      {
        title: "Step 10:",
        info: "Step 10: Upload a photo- this will be used as a means of identification when you go to an hospital to claim the plan purchased.",
        imgUrl: "/img/Step10_toccby.jpg",
      },
      {
        title: "Step 11:",
        info: "Step 11: Fill in your details- first name, last name, gender, date of birth, phone number, email address and select the duration you want to buy a plan for.",
        imgUrl: "/img/Step11_uvp6u1.jpg",
      },
      {
        title: "Step 12:",
        info: 'Step 12: Click on the "purchase plan" button to finish the process.',
        imgUrl: "/img/Step12_ltmcth.jpg",
      },
    ],
  },
  {
    link: "",
    id: 10,
    imgUrl: "/img/How_to_view_sigve7.jpg",
    title: "How to view the status of your health plan",
    subtitle: "Modified on: Tue, 16 May, 2023",
    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Click on the accounts icon at the bottom of your screen.",
        imgUrl: "/img/Step_1_1_efrcqa.jpg",
      },
      {
        title: "Step 2:",
        info: 'Step 2: Click on "my insurance plan"',
        imgUrl: "/img/Step_2_r5vevf.jpg",
      },
    ],
  },
  {
    link: "",
    id: 11,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1700735551/Website%20Support%20Folder/Household%20User/How%20to%20Refer/How_to_view_zghfec.jpg",
    title: "How to join Pakam Referral Program",
    subtitle: "Modified on: Thur, 23 November, 2023",
    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Open the Pakam app and click on your accounts page within the app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1700735552/Website%20Support%20Folder/Household%20User/How%20to%20Refer/Step_1_jj8gh0.jpg",
      },
      {
        title: "Step 2:",
        info: "Step 2: Click on referrals",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1700735557/Website%20Support%20Folder/Household%20User/How%20to%20Refer/Step_2_dqn9xp.jpg",
      },
      {
        title: "Step 3:",
        info: "Step 3: Click on the share or copy button to share your unique code to family and friends.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1700735555/Website%20Support%20Folder/Household%20User/How%20to%20Refer/Step_3_trrrea.jpg",
      },
      {
        title: "Step 4:",
        info: "Step 4: Click on the completed tab to view your friend's schedule(s) and see your earned rewards.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1700735553/Website%20Support%20Folder/Household%20User/How%20to%20Refer/Step_4_lwpgbg.jpg",
      },
    ],
  },
];
export const recyclerSupport = [
  {
    link: "",
    id: 1,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676988495/Website%20Support%20Folder/Recycler%20Images/How_to_sign_up_on_the_Pakam_collector_s_application_nhm2na.jpg",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",
    title: "How to sign up on the Pakam collector’s application",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1 :Download the Pakam collector's application from Google play store (android) or Apple store (iOS)",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896302/Website%20Support%20Folder/Recycler%20Images/How%20to%20sign%20up%20on%20the%20Pakam%20collector%27s%20application/Step_1_lxylde.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Open the Pakam application and click on “get started” to create an account.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676994919/Website%20Support%20Folder/Recycler%20Images/How%20to%20sign%20up%20on%20the%20Pakam%20collector%27s%20application/Step_2_edmglm.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Fill in the fields and select the organization you’re a collector for.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676994919/Website%20Support%20Folder/Recycler%20Images/How%20to%20sign%20up%20on%20the%20Pakam%20collector%27s%20application/Step_3_qlhfja.jpg",
      },

      {
        title: "Step 4:",
        info: "Step 4: Verify the phone number used in step 3 by entering the 4 digit code sent to that number via sms and clicking on verify code.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676994919/Website%20Support%20Folder/Recycler%20Images/How%20to%20sign%20up%20on%20the%20Pakam%20collector%27s%20application/Step_4_zxe1me.jpg",
      },

      {
        title: "Step 5:",
        info: "Step 5: After the number has been successfully verified, you would be required to login.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676994919/Website%20Support%20Folder/Recycler%20Images/How%20to%20sign%20up%20on%20the%20Pakam%20collector%27s%20application/Step_5_ko6c3e.jpg",
      },

      {
        title: "Step 6:",
        info: "Step 6: After you log in as a new user, you would be asked to set up your profile- enter your aggregator’s ID and any other details required.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676994919/Website%20Support%20Folder/Recycler%20Images/How%20to%20sign%20up%20on%20the%20Pakam%20collector%27s%20application/Step_6_uted1q.jpg",
      },
    ],
  },
  // {
  //   link: "",
  //   id: 2,
  //   imgUrl:
  //     "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676988494/Website%20Support%20Folder/Recycler%20Images/How_to_accept_a_collector_on_the_company_s_dashboard_iowdy1.jpg",
  //   title: "How to accept a collector on the company's dashboard",
  //   subtitle: "Modified on: Mon, 27 Feb, 2023 ",

  //   steps: [
  //     {
  //       title: "Step 1:",
  //       info: "Step 1: Log in to https://dashboard.pakam.ng/ (Tick the company box)",
  //       imgUrl:
  //         "https://res.cloudinary.com/dnwmze4x4/image/upload/v1677491571/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20collector%20on%20the%20company%27s%20dashboard/Step_1_gjek0t.png",
  //     },

  //     {
  //       title: "Step 2:",
  //       info: "Step 2: Enter your email and password",
  //       imgUrl:
  //         "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896502/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20collector%20on%20the%20company%27s%20dashboard/Step_2_whoaof.jpg",
  //     },

  //     {
  //       title: "Step 3:",
  //       info: "Step 3: Click on total collectors",
  //       imgUrl:
  //         "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896503/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20collector%20on%20the%20company%27s%20dashboard/Step_3_d1jaeq.jpg",
  //     },

  //     {
  //       title: "Step 4:",
  //       info: "Step 4: Scroll to the table and click on the pending collectors tab.",
  //       imgUrl:
  //         "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896503/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20collector%20on%20the%20company%27s%20dashboard/Step_4_vnsk1h.jpg",
  //     },

  //     {
  //       title: "Step 5:",
  //       info: "Step 5: Click on the accept button.",
  //       imgUrl:
  //         "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896503/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20collector%20on%20the%20company%27s%20dashboard/Step_5_liu5vg.jpg",
  //     },
  //     { isactive: true },
  //   ],
  // },

  {
    link: "",
    id: 2,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676988495/Website%20Support%20Folder/Recycler%20Images/How_to_accept_and_complete_a_pickup_task_mjvnqa.jpg",
    title: "How to accept and complete a pick up task",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the collectors’ app,",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896160/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20and%20complete%20a%20pickup%20task/Step_1_yprsji.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on pick up task on the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896159/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20and%20complete%20a%20pickup%20task/Step_2_yu800e.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on a task to see more information, then, click on the accept schedule button.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1677493223/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20and%20complete%20a%20pickup%20task/Step_3_n1ijpz.jpg",
      },
      {
        title: "Step 4:",
        info: "Step 4: Click on navigate, which takes you to your google map for directions on how to get to your pick up location.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896160/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20and%20complete%20a%20pickup%20task/Step_4_wz5gso.jpg",
      },

      {
        title: "Step 5:",
        info: "Step 5: Return to the app. and click on the “complete task” button.",
        imgUrl:
          " https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896160/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20and%20complete%20a%20pickup%20task/Step_5_awx0nx.jpg",
      },

      {
        title: "Step 6:",
        info: "Step 6: Enter the quantity of waste weighed into the individual fields and click on “confirm completion.“",
        imgUrl:
          " https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896161/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20and%20complete%20a%20pickup%20task/Step_6_mfzoib.jpg",
      },
    ],

    NB: "N.B: You can also send a message or place a call to the household you would be picking up from.“",
  },

  {
    link: "",
    id: 3,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676988495/Website%20Support%20Folder/Recycler%20Images/How_to_access_accepted_tasks_that_have_not_been_completed_w3rrsb.jpg",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",
    title: "How to accept a drop-off task",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the rider's app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895709/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20accepted%20tasks%20that%20have%20not%20been%20completed/Step_1_sc82v1.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on activities at the bottom of the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895711/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20accepted%20tasks%20that%20have%20not%20been%20completed/Step_2_ibnrxz.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on accepted.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895710/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20accepted%20tasks%20that%20have%20not%20been%20completed/Step_3_wyhvjf.jpg",
      },

      {
        title: "Step 4:",
        info: "Step 4: Click on a task to be completed and click on continue schedule which takes you to the page where you click on navigate.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895709/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20accepted%20tasks%20that%20have%20not%20been%20completed/Step_4_jlifqc.jpg",
      },

      {
        title: "Step 5:",
        info: "Step 5: Click on navigate which takes you to your google map for directions on how to get to your pick up location.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895710/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20accepted%20tasks%20that%20have%20not%20been%20completed/Step_5_hwghpd.jpg",
      },

      {
        title: "Step 6:",
        info: "Step 6: Return to the app. and click on the “complete task” button.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895710/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20accepted%20tasks%20that%20have%20not%20been%20completed/Step_6_jpk9b5.jpg",
      },

      {
        title: "Step 7:",
        info: "Step 7: Enter the quantity of wastes weighed into the individual fields and click on “click completion”.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895709/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20accepted%20tasks%20that%20have%20not%20been%20completed/Step_7_vpq9of.jpg",
      },
    ],
  },

  {
    link: "",
    id: 4,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676988495/Website%20Support%20Folder/Recycler%20Images/How_to_access_accepted_tasks_that_have_not_been_completed_w3rrsb.jpg",
    title: "How to access accepted tasks that have not been completed",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",
    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the collectors' app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895957/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20drop-off%20task/Step_1_vvgh8a.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on “drop-off task“ on the home page. Note: Drop-off tasks can only be completed when the household user has brought the recyclables.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895957/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20drop-off%20task/Step_2_k9ahe0.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on a task to see more information and confirm the details of the task.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895957/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20drop-off%20task/Step_3_oe7sah.jpg",
      },

      {
        title: "Step 4:",
        info: "Step 4: Click on “complete drop-off” and enter the quantity of waste weighed.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895955/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20drop-off%20task/Step_4_dgxeea.jpg",
      },

      {
        title: "Step 5:",
        info: "Step 5: Click on “confirm completion”",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895957/Website%20Support%20Folder/Recycler%20Images/How%20to%20accept%20a%20drop-off%20task/Step_5_rlr2ch.jpg",
      },
    ],
  },

  {
    link: "",
    id: 5,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676988495/Website%20Support%20Folder/Recycler%20Images/How_to_miss_a_pickup_task_v7xa36.jpg",
    title: "How to miss a pick up task",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the collectors’ app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896053/Website%20Support%20Folder/Recycler%20Images/How%20to%20miss%20a%20pick%20up%20task/Step_1_uhw7dm.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on pick up task on the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896051/Website%20Support%20Folder/Recycler%20Images/How%20to%20miss%20a%20pick%20up%20task/Step_2_oddd8o.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on a task to see more information, then, click on the accept schedule button.",
        imgUrl:
          " https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896051/Website%20Support%20Folder/Recycler%20Images/How%20to%20miss%20a%20pick%20up%20task/Step_3_dz5nlo.jpg",
      },

      {
        title: "Step 4:",
        info: "Step 4: Click on “missed” on the top right corner of your screen and type in your reason.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896052/Website%20Support%20Folder/Recycler%20Images/How%20to%20miss%20a%20pick%20up%20task/Step_4_a50v6j.jpg",
      },

      {
        title: "Step 5:",
        info: "Step 5: Click on submit to complete that action.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676896053/Website%20Support%20Folder/Recycler%20Images/How%20to%20miss%20a%20pick%20up%20task/Step_5_yxryjr.jpg",
      },
    ],
  },

  {
    link: "",
    id: 6,
    imgUrl:
      "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676988494/Website%20Support%20Folder/Recycler%20Images/How_to_access_other_activities_that_have_been_done_on_the_app_ajczvk.jpg",
    title: "How to access other activities that have been done on the app.",
    subtitle: "Modified on: Mon, 27 Feb, 2023 ",

    steps: [
      {
        title: "Step 1:",
        info: "Step 1: Log in to your account on the rider's app.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895857/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20other%20activities%20that%20have%20been%20done%20on%20the%20app./Step_1_nhe3na.jpg",
      },

      {
        title: "Step 2:",
        info: "Step 2: Click on activities at the bottom of the home page.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895854/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20other%20activities%20that%20have%20been%20done%20on%20the%20app./Step_2_mvq4yr.jpg",
      },

      {
        title: "Step 3:",
        info: "Step 3: Click on completed to see the list of pick-ups and drop-offs that have been completed.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895854/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20other%20activities%20that%20have%20been%20done%20on%20the%20app./Step_3_zih88u.jpg",
      },

      {
        title: "Step 4:",
        info: "Step 4: Click on missed to see the list of pick-ups that were missed.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895855/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20other%20activities%20that%20have%20been%20done%20on%20the%20app./Step_4_h41a6b.jpg",
      },

      {
        title: "Step 5:",
        info: "Step 5: Click on transactions to see the total amount of waste that have been completed and also recent collection done.",
        imgUrl:
          "https://res.cloudinary.com/dnwmze4x4/image/upload/v1676895855/Website%20Support%20Folder/Recycler%20Images/How%20to%20access%20other%20activities%20that%20have%20been%20done%20on%20the%20app./Step_5_vwtki7.jpg",
      },
    ],
  },
];

export const HowItWork = [
  {
    img: "/img/handshake.png",
    heading: "Identify Partner Type",
    body: "Select your mode of partnership with Pakam, either as a collector, tricycle owner, or empty space owner. ",
  },
  {
    img: "/img/Signin.png",
    heading: "Get Registered",
    body: "Sign up and participate in the free training and onboarding session. ",
  },
  {
    img: "/img/EarnOnSocials.png",
    heading: "Start earning",
    body: "Collect recyclable materials with your resources and get paid.",
  },
];

export const SignupData = [
  {
    img: "/img/Rectangle3.png",
    heading: "A Collector",
    body: "Individuals who are interested in becoming collectors of recyclable materials from households and businesses.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdAx2A5M2kDLpAw6yE41pkhPSBbKl11pzJSREU_xiXEvQszyw/viewform",
    // link: "https://forms.zohopublic.com/pakamtechnologylimited/form/REGISTRATIONOFRECYCLINGBUSINESSPARTNERS/formperma/Itltm_rbhvj42EHvTbzAfMgya0sIQafsssEZj0iUeO0",
  },
  {
    img: "/img/Rectangle1.png",
    heading: "A Logistics Owner",
    body: "Individuals who run logistics and have or are willing to invest in tricycles.",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdAx2A5M2kDLpAw6yE41pkhPSBbKl11pzJSREU_xiXEvQszyw/viewform",
    // link: "https://forms.zohopublic.com/pakamtechnologylimited/form/WASTEPICKERSREGISTRATION/formperma/590lVk12sJ35PJwvKzV7SXvGS9vce6URWx8qcxcGHwM",
  },

  {
    img: "/img/Rectangle2.png",
    heading: "A Space Owner",
    body: "Individuals who have empty lands that are well secured in accessible areas",
    link: "https://docs.google.com/forms/d/e/1FAIpQLSdAx2A5M2kDLpAw6yE41pkhPSBbKl11pzJSREU_xiXEvQszyw/viewform",
    // link: "https://forms.zohopublic.com/pakamtechnologylimited/form/PAKAMSPACEOWNERSREGISTRATION/formperma/DGaTy7oPVQS32JY9cKtlFZ12Pm0BcwewygSq9jcW0_4",
  },


];

export const WhyPakam = [
  {
    img: "/img/moneyandphone.png",
    
    heading: "Easy Money",
    body: "Make more money by collecting recyclable materials from your community.",
  },

  {
    img: "/img/close.png",
    heading: "Be Your Boss",
    body: "Earn independently when your space, tricycle, or proximity helps you earn easy money on the side.",
    center: false,
  },

  {
    img: "/img/recyclingearth.png",
    heading: "Enable Waste Scarcity",
    body: "Join the force to ensure waste shortage, one community at a time.",
  },
];



export const BlogData = [
  {
    img: "/img/image 53Broke.png",
    link: "Broke? Here’s How Recycling Could Be Your Secret Financial Lifeline",
    linkUrl: "https://medium.com/@pakam.ng/broke-heres-how-recycling-could-be-your-secret-financial-lifeline-06988ac5070f",
    date: "February 12 ,2024"
  },
  {
    img: "/img/image 53Ketu.png",
    link: "Do Ketu People Sleep At All",
    linkUrl: "https://medium.com/@pakam.ng/do-ketu-people-sleep-at-all-47acefd807cb",
    date: "February 7 ,2024"
  },
  {
    img: "/img/image 53Organic.png",
    link: "It’s So 90s to Burn Organic Wastes! Here’s What You Should do Instead",
    linkUrl: "https://medium.com/@pakam.ng/its-so-90s-to-burn-organic-wastes-here-s-what-you-should-do-instead-ec0868dbc6f6",
    date: "Februar 5 ,2024"
  },
  {
    img: "/img/image 53Ikorodu.png",
    link: "IKORODU PEOPLE ALMOST MADE AWAY WITH OUR HEADS",
    linkUrl: "https://medium.com/@pakam.ng/ikorodu-people-almost-made-away-with-our-heads-5087abd76fe7",
    date: "January 26 ,2024"
  },
  {
    img: "/img/image 53Alternate.png",
    link: "Alternative Packaging Materials for Lagos Businesses Now That Styrofoam has been Banned",
    linkUrl: "https://medium.com/@pakam.ng/alternative-packaging-materials-for-lagos-businesses-now-that-styrofoam-has-been-banned-2462ef789b99",
    date: "January 23 ,2024"
  },
  {
    img: "/img/image 53Evict.png",
    link: "Evict theses wastes from your household before they evict you",
    linkUrl: "https://medium.com/@pakam.ng/evicts-these-wastes-from-your-apartment-before-they-kick-you-out-45c04e1c6b18",
    date: "January 23 ,2024"
  },
  {
    img: "/img/image 53bariga.png",
    link: "Travails Of Pakam: How We Failed to Loot Plastics in Bariga",
    linkUrl: "https://www.figma.com/exit?url=https%3A%2F%2Fmedium.com%2F%40pakam.ng%2Ftravails-of-pakam-how-we-failed-to-loot-plastics-in-bariga-3bae93e4a519",
    date: "January 14 ,2024"
  },
  {
    img: "/img/image 53moneyInwaste.png",
    link: "There’s Money in Wastes: Here’s What You Need to Know",
    linkUrl: "https://medium.com/@pakam.ng/theres-money-in-wastes-here-s-what-you-need-to-know-81255bf9e6a2",
    date: "January 9, 2024"
  },
  {
    img: "/img/transform.png",
    link: "Zero Waste Practices in Nigerian Businesses: How Pakam Technology Can Transform Sustainability.",
    linkUrl: "https://medium.com/@pakam.ng/zero-waste-practices-in-nigerian-businesses-how-pakam-technology-can-transform-sustainability-84805de391c7",
    date: "November 27, 2023"
  },  
  {
    img: "/img/revolution.png",
    link: "One Recyclable at a Time. Revolutionizing Sustainability: Pakam Technology and the Path to a Greener Future ",
    linkUrl: "https://medium.com/@pakam.ng/revolutionizing-sustainability-pakam-technology-and-the-path-to-a-greener-future-5693a332e746",
    date: "November 27, 2023"
  },
  {
    img: "/img/refuse.png",
    link: "Pakam is Transforming the World, One Recyclable at a Time",
    linkUrl: "https://medium.com/@pakam.ng/pakam-is-transforming-the-world-one-recyclable-at-a-time-77626eaaaa60",
    date: "November 27, 2023"
  },





];

export const values = [
  {
    imgUrl: ProblemSolvingTeam,
    text: "Collaboration.",
  },
  {
    imgUrl: OpenUp,
    text: "Openness.",
  },
  {
    imgUrl: MorphingGreen,
    text: "Ownership.",
  },
  {
    imgUrl: SocialNetwork,
    text: "Social Impact",
  },
  {
    imgUrl: Technology,
    text: "Innovation.",
  },
];


export const subscription: Array<TechnologyModel> = [
  {
    imgUrl: analytics,
    title: "Earn-as-You Waste",    
    text: "Earn-as-You Waste is a recycling service that connects waste generators to waste collectors/aggregators in real-time, ensuring individuals capture value for their waste.",

    link: "/products/earn",
  },
  {
    imgUrl: payment,
    title: "Pay-as-You Waste",
    text: `Pay-as-You Waste is a full waste management application that
    enables citizens to dispose of their non-recyclable and
    specialized waste to collectors properly.`,
    link: "products/pay",
  },
  {
    imgUrl: thumbs,
    title: "Smart Enforcement",
    text: "The Pakam Smart Enforcement is an integrated environmental reporting platform that enables the public to report environmental infractions to environmental agencies faster, better, and in a unified manner. ",
    link: "products/smart",
  },
];


export const recycledata = [
  {
    icon:'/img/rec1.png',
    title:'Convenient Recycling',
    desc: 'Users have the option to schedule a pickup for their recyclable waste or drop it off at designated locations, making recycling easy and hassle free.'
  },
  {
    icon:'/img/rec2.png',
    title:'Environmental Impact',
    desc: 'Your recycling actions contribute to a cleaner environment and a healthier planet. Every recyclable item you divert from landfills makes a positive impact.'
  },
  {
    icon:'/img/rec3.png',
    title:'Earn rewards with Purpose',
    desc: 'Use your earnings to purchase health insurance at an affordable price, donate to registered charities, or payout to your local bank account, all within the app.'
  },
  {
    icon:'/img/rec4.png',
    title:'Data for Smart Enforcement',
    desc: 'Pakam provides users and governments with valuable data for smart enforcement, enabling more effective waste management strategies.'
  },
]
export const wastedata = [
  {
    icon:'/img/Shampoo.png',
    title:'Pet Bottles',
  },
  {
    icon:'/img/Nylon.png',
    title:'Nylons',
  },
  {
    icon:'/img/Essay.png',
    title:'Papers',
  },
  {
    icon:'/img/Shopping bag.png',
    title:'Shopping Bags',
  },
  {
    icon:'/img/Can.png',
    title:'Cans',
  },
  {
    icon:'/img/Carton box.png',
    title:'Cartons',
  },
]