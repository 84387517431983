import React, {useState, useEffect, useRef} from 'react'
import {useLocation, Link, NavLink} from 'react-router-dom'
import { data } from './navdata';
import NavButton from './NavButton';
import LinkBtn from '../common/LinkBtn';
import { FaBars, FaTimes } from "react-icons/fa";
import { Container } from '../common/AltCard';
import './header.css'

const findActiveTitle = (currentPath: string) => {
    for (const item of data) {
      for (const link of item.dropdown) {
        if (currentPath.includes(link.link)) {
          return item.title;
        }
      }
    }
    return ""; // Default if no match is found
  };

const Navbar = () => {

    const [scrollNavbar, setScrollNavbar] = useState(false);
    const [primary, setPrimary] = useState(true);
    const [white, setWhite] = useState(true);
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
    const appNav = useRef<any>(null);
    const router = useLocation();
  
    const [activeTitle, setActiveTitle] = useState<string>("");
  

    useEffect(() => {
        const currentPath = router.pathname;
        const foundTitle = findActiveTitle(currentPath);
        setActiveTitle(foundTitle);
      }, [router.pathname]);
    
      const ChangeBackground = () => {
        if (window.scrollY >= 80) {
          setScrollNavbar(true);
        } else {
          setScrollNavbar(false);
        }
      };

    useEffect(() => {
        window.addEventListener("scroll", ChangeBackground);
        return () => {
          window.removeEventListener("scroll", ChangeBackground);
        };
      }, []);
    
     

  return (
    <div className='w-full xl:w-full fixed z-[9999] '>
      <div className={`header relative ${scrollNavbar? 'shadow-lg': ''}`} >
        <Container>
          <div className=' flex items-center justify-between w-full '>
            

        <Link to='/'>
              <img src="/svg/pkam-logo.svg" alt="logo" className='w-[120px] h-[40px]' width={120.68} height={40}/>
          </Link>

          <button onClick={handleClick} className='lg:hidden block  top-0 right-0 text-3xl cursor-pointer'>{click? <FaTimes/> : <FaBars/>}</button>

          <div className='hidden lg:flex items-center '>
            <div className='flex lg:space-x-3 cursor-pointer flex-col lg:flex-row  '>
            {data.map((item, index) => {
                    return (
                      <div  key={index}>
                        <NavButton
                          Click={handleClick}
                          title={item.title}
                          list={item.dropdown}
                          active={item.title === activeTitle ? true : false}
                        />
                      </div>
                    );
                  })}
            </div>
            <Link style={{color: 'rgba(0, 0, 0, 1)', borderColor:'rgba(0, 0, 0, 1)'}} 
          className={`${router.pathname === '/be-your-own-boss' ?  'border-b' : 'border-none'} ml-4 !text-[#005700] pb-1 font-[800] text-[16px]`} to='/be-your-own-boss'>Become a Partner</Link>       
          </div>

          {/* mobile nav */}
          <div style={{backgroundColor:'rgba(248, 255, 244, 1)'}} className={` lg:hidden w-full h-[95vh] lg:h-full top-[80px] lg:top-0 left-0 flex items-center gap-10 lg:space-y-0 lg:space-x-3 absolute lg:relative flex-col lg:flex-row transition-all ease-in-out ${click? 'translate-x-[0]' : 'translate-x-[-800px]'}`}>
            <div className='flex lg:space-x-3 cursor-pointer flex-col lg:flex-row w-full'>
            {data.map((item, index) => {
                    return (
                      <div  key={index}>
                        <NavButton
                          Click={handleClick}
                          title={item.title}
                          list={item.dropdown}
                          active={item.title === activeTitle ? true : false}
                        />
                      </div>
                    );
                  })}
            </div>
            <div onClick={handleClick} className='w-full flex justify-center items-center py-2'>
              <Link to='/be-your-own-boss' className='text-primary hover:font-[800] font-[700]'>Become a Partner</Link>
            </div>
              
            <div onClick={handleClick} className='lg:hidden mt-6'><LinkBtn link='/contact-us' title='Contact Us'/></div>        
          </div>
          
          {/* wide screen contact us */}
          <div  className='lg:block hidden'>
            <LinkBtn link='/contact-us' title='Contact Us'/>
          </div>

          </div>
        </Container>

          
      </div>
    </div>

  )
}

export default Navbar
