import React from 'react'
import { Container } from '../../components/common/AltCard'
import { SignUp } from '../../components/partner/SignUp'
import { HowItWorks } from '../../components/partner/HowItWorks'
import { Partners } from '../../components/partner/Partners'
import { Pakam } from '../../components/partner/Pakam'
import { LongCard } from '../../components/partner/LongCard'
import { PartnerCard } from '../../components/partner/PartnerCard'

const BPartner = () => {
  return (
    <>
      <div className="bg-secondary">
        <Container>
          <Partners />

        </Container>
      </div>
      <div>
        <Container>
          <HowItWorks />

          <div className="ml-20 ">
            <img
              src="/img/31021806_coin_bg280-removebg-preview 5.png"
              width="100px"
              height="100px"
              className='object-contain'
            />
          </div>
          <SignUp />
          <Pakam />
          <LongCard />
          <div className='py-4 lg:py-8'>
            <PartnerCard />            
          </div>

        </Container>
      </div>
    </>
  )
}

export default BPartner
